import React from "react";
import {Col, Row} from "reactstrap";
import './Breadcrumb.css';

export default function Breadcrumb(props: any) {
    const {classes, title, imageBreadcrumb} = props;
    console.log(imageBreadcrumb)
    return (
        <>
            {
                (imageBreadcrumb === "") ? (
                    <Row className="breadcrumbRow">
                        <Col xs={10} sm={10} md={10}>
                            <h1 style={{textAlign: 'right', fontSize: '40px'}} className={classes.breadcrumbHeader}>{title}</h1>
                        </Col>
                    </Row>
                ) : (
                    (imageBreadcrumb.style === "overlay") ?
                    (<Row style={{backgroundColor: '#0063A8', marginTop: '-10px', position: 'relative'}}>
                        <Col style={{padding: 0}} xs={12} sm={12} md={12}>
                            <img style={{width: '100%',height: '400px', objectFit: "cover"}} src={imageBreadcrumb.url} alt={imageBreadcrumb.altText} />
                            <h1 className="imageBreadcrumbText">{imageBreadcrumb.text}</h1>
                        </Col>
                    </Row>) :
                    (<Row style={{backgroundColor: '#0063A8', marginTop: '-10px', position: 'relative'}}>
                        <Col style={{padding: 0}} xs={12} sm={12} md={12}>
                            <img style={{width: '100%',height: '400px', objectFit: "cover"}} src={imageBreadcrumb.url} alt={imageBreadcrumb.altText} />
                            <div style={{background: 'linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7)', marginBottom: '-10px'}}>
                                <h1 style={{fontSize: '40px', textAlign: 'center', padding: '24px 0', color: 'white', letterSpacing: '-2.25px', textTransform: 'uppercase'}}>{imageBreadcrumb.text}</h1>
                            </div>
                        </Col>
                    </Row> 
                    )
                )
            }
            
        </>
    );
};