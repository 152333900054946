import React, {useState} from "react";
import {Col, Row} from "reactstrap";
import {withRouter} from "react-router";
import Swiper from "react-id-swiper";
import * as moment from "moment";
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import './OCVBlogDetail.css';

export default withRouter(function OCVBlogDetail(props: any) {
    const [currentImage, setCurrentImage] = useState(0);
    const [lightboxIsOpen, setLightbox] = useState(false);

    const openLightbox = (event: {preventDefault: () => void}, index : number) => {
        event.preventDefault();
        setCurrentImage(index);
        setLightbox(true);
    };
    const closeLightbox = () => {
        setLightbox(false);
    };
    const gotoPrevious = () => {
        setCurrentImage(currentImage - 1);
    };
    const gotoNext = () => {
        setCurrentImage(currentImage + 1);
    };

    const {route, blogEntries, subtypes, history} = props;
    const test = blogEntries.find((obj : any) => {
        return (obj['_id']['$id'] === props['match']['params']['id']);
    });
    let images: any[] = [];
    if (test.length) {
        for (let i=0; i < test.images.length; i++) {
            images.push({src: test.images[i].large});
        }
    }
    const params = {
        parallax: true,
        slidesPerView: 1,
        speed: 3000,
        loop: true,
        autoplay: {
            disableOnInteraction: true
        },
        effect: 'fade'
    };
    return (
        <Row>
            {subtypes.includes("imageAtTop") && (test.images.length) !== 0 ? <Col className='OCVBlogDetailColOne' xs={10} sm={8} md={4}>
                <Swiper {...params}>
                    {test.images.map((image: {large: string, small: string}, index: number) => (
                        <img alt={image.large} onClick={(e) => openLightbox(e, index)} key={index} height={400} src={test.images.length !== 0 ? image.large : ' '} />
                    ))}
                </Swiper>
                <Lightbox onClickNext={gotoNext} onClickPrev={gotoPrevious} backdropClosesModal currentImage={currentImage} onClose={closeLightbox} isOpen={lightboxIsOpen} images={images} />
            </Col>: ""}
            <Col className='OCVBlogDetailColTwo' xs={10}>
                <button className={"OCVBlogDetailButton btn btn-primary"} onClick={() => history.push('/' + route)}>&larr; Back to List</button>
                <Paper className='OCVBlogDetailPaper'>
                    <h1>{test.title}</h1>
                    {(("date" in test) && !subtypes.includes('noDates')) ? <span className='OCVBlogDetailSpan'><strong>Posted on {moment.unix((test.date !== null) ? test['date']['sec'] : '').format('LL')}</strong></span> : ''}
                    <div dangerouslySetInnerHTML={{__html: test.content}} />
                </Paper>
            </Col>
            {!subtypes.includes("imageAtTop") && (test.images.length) !== 0 ? <Col className='OCVBlogDetailColOne' xs={10} sm={8} md={4}>
                <Swiper {...params}>
                    {test.images.map((image: {large: string, small: string}, index: number) => (
                        <img alt={image.large} onClick={(e) => openLightbox(e, index)} key={index} height={400} src={test.images.length !== 0 ? image.large : ' '} />
                    ))}
                </Swiper>
                <Lightbox onClickNext={gotoNext} onClickPrev={gotoPrevious} backdropClosesModal currentImage={currentImage} onClose={closeLightbox} isOpen={lightboxIsOpen} images={images} />
            </Col>: ""}
        </Row>
    );
});