import React from 'react';
import {Col, Row} from "reactstrap";
import OCVPhotoGallery from '../OCVPhotoGallery/OCVPhotoGallery';

export default function OCVMultiPhotoGallery(props: any) {
console.log(props)
    return (
        <>
            <Row style={{padding: "24px 0"}}>
                { 
                    props.galleries.map((item: any) => (
                        <Col style={{borderBottom: '1px solid, #707070', paddingBottom: '32px'}} xs={10} sm={10} md={10}>
                            <h2 style={{textAlign: 'center', color: '#0063A8'}}>{item.title}</h2>
                            <OCVPhotoGallery link={item.link}/>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
};