import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Col, Row } from 'reactstrap';
import Swiper from 'react-id-swiper';
import Lightbox from 'react-images';
import './OCVPageFAQ.css';
import OCVVideoPlayer from '../OCVVideoPlayer/OCVVideoPlayer';

interface Props {
  classes: any;
  link: string;
  subtypes: any;
  faqLink: string;
  video?: string;
  text?: string;
}

export default function OCVPageFAQ(props: Props) {
  const [pageData, setPageData] = useState<any>(null);
  const [faqData, setFAQData] = useState<any>(null);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    setCurrentImage(index);
    setLightboxIsOpen(true);
  };
  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };
  const gotoPrevious = () => {
    setCurrentImage(currentImage - 1);
  };
  const gotoNext = () => {
    setCurrentImage(currentImage + 1);
  };

  useEffect(() => {
    const getPage = async () => {
      try {
        await fetch(props.link)
          .then((response) => response.json())
          .then((data) => {
            setPageData(data['data']);
          });
      } catch (e) {
        console.log(e);
      }
    };
    getPage();
  }, [props.link]);

  useEffect(() => {
    const getFAQS = async () => {
      try {
        await fetch(props.faqLink)
          .then((response) => response.json())
          .then((data) => {
            setFAQData(data);
          });
      } catch (e) {
        console.log(e);
      }
    };
    getFAQS();
  }, [props.faqLink]);

  let images: any[] = [];
  if (pageData !== null) {
    for (let i = 0; i < pageData.images.length; i++) {
      images.push({ src: pageData.images[i].large });
    }
  }
  if (pageData === null && faqData === null) {
    return (
      <div className="OCVPageExternalDiv">
        <ReactLoading
          className="loading-centered"
          type={'bars'}
          color={'#FFF'}
          height={'10%'}
          width={'10%'}
        />
      </div>
    );
  } else {
    const params = {
      parallax: true,
      slidesPerView: 1,
      speed: 3000,
      autoplay: {
        disableOnInteraction: true,
      },
      autoHeight: true,
    };
    const { subtypes, video, text } = props;
    console.log(video, text);
    return (
      <>
        <Row>
          {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
          {video && (
            <Col xs={10}>
              <OCVVideoPlayer config={{ link: video }} />
            </Col>
          )}
          {pageData &&
          subtypes.includes('imageAtTop') &&
          pageData.images.length !== 0 ? (
            <Col className="OCVPageColOne" xs={10} sm={10} md={10}>
              <Swiper {...params}>
                {pageData.images.map(
                  (image: { large: string; small: string }, index: number) => (
                    <div key={index}>
                      <img
                        height={400}
                        onClick={(e) => openLightbox(e, index)}
                        alt={image.large}
                        className="OCVPageImage"
                        src={image.large}
                      />
                    </div>
                  )
                )}
              </Swiper>
              <Lightbox
                onClickNext={gotoNext}
                onClickPrev={gotoPrevious}
                backdropClosesModal
                currentImage={currentImage}
                onClose={closeLightbox}
                isOpen={lightboxIsOpen}
                images={images}
              />
            </Col>
          ) : (
            ''
          )}
          {pageData && (
            <Col className="OCVPageColTwo" xs={10}>
              <div className="OCVPagePaper">
                <div
                  className="OCVPageDiv"
                  dangerouslySetInnerHTML={{ __html: pageData.content }}
                />
              </div>
            </Col>
          )}
          {pageData &&
          !subtypes.includes('imageAtTop') &&
          pageData.images.length !== 0 ? (
            <Col className="OCVPageColOne" xs={10} sm={10} md={10}>
              <Swiper {...params}>
                {pageData.images.map(
                  (image: { large: string; small: string }, index: number) => (
                    <div key={index}>
                      <img
                        height={600}
                        onClick={(e) => openLightbox(e, index)}
                        alt={image.large}
                        className="OCVPageImage"
                        src={image.large}
                      />
                    </div>
                  )
                )}
              </Swiper>
              <Lightbox
                onClickNext={gotoNext}
                onClickPrev={gotoPrevious}
                backdropClosesModal
                currentImage={currentImage}
                onClose={closeLightbox}
                isOpen={lightboxIsOpen}
                images={images}
              />
            </Col>
          ) : (
            ''
          )}
          <Col xs={12}>
            <div
              style={{
                padding: '24px 0',
                margin: '32px -20px 0',
                background:
                  'linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7)',
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '40px',
                  fontWeight: 600,
                }}
              >
                FAQs
              </h2>
            </div>
            <div style={{ padding: '40px' }}>
              {
                //JSON.stringify(faqData)
                faqData &&
                  faqData.map((faq: any, index: number) => (
                    <div
                      style={{
                        borderBottom:
                          index === faqData.length - 1
                            ? ''
                            : '1px solid #484848',
                        padding: '24px 0px',
                        textAlign: 'center',
                      }}
                    >
                      <h3 style={{ color: '#0063A8' }}>{faq.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: faq.content }}
                      ></div>
                    </div>
                  ))
              }
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
