import React from 'react';
import {withFormsy} from 'formsy-react';
import {withStyles} from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch/Switch";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import './OCVFormComponents.css';

const styles = (theme: any) => ({
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: '#52d869'
            }
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        })
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none'
        }
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },
    iOSIcon: {
        width: 24,
        height: 24
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1]
    }
});

export default withStyles(styles)(withFormsy(function OCVSwitch(props: any) {
    const changeValue = (event: {target: any}) => {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        props.setValue(event.target.checked);
    };

    const {label, classes, getValue, getErrorMessage} = props;
    return (
        <div className='OCVFormComponentsDiv'>
            <FormControl style={{display: 'inline-block'}} fullWidth={true}>
                <FormLabel>{label}</FormLabel>
                    <Switch
                        classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked,
                        }}
                        value={getValue()}
                        onChange={changeValue}
                    />
                <span className='OCVFormComponentsSpan'>{getErrorMessage()}</span>
            </FormControl>
        </div>
    );
}));
