import React, {useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import {NavLink, Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ReactModal from "react-modal";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";
import FacebookWidget from "../FacebookWidget";
import TwitterWidget from "../TwitterWidget";
import OCVBlogSlider from "../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import HorizontalSublayout from "../HorizontalSublayout/HorizontalSublayout";
import './NavSubmenu.css';

ReactModal.setAppElement('#root');

export default function NavSubmenu(props: any) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalView, setModalView] = useState(Object.keys(props.manifestData['views'])[0]);
    const [modalTitle, setModalTitle] = useState("");

    const handleOpenModal = (view: string, title: string) => {
        setShowModal(true);
        setModalView(view);
        setModalTitle(title);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const getView = (view: string, manifestData: any, classes: any) => {
        switch (manifestData['views'][view]['type']) {
            case 'imageView':
                return <img className={"NavSubmenuImg img-fluid"} src={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[manifestData['views'][view]['config']['image']]['url'] : ''} alt={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[manifestData['views'][view]['config']['image']]['altText'] : ''}/>;
            case 'button':
                return <div style={{
                    paddingTop: (manifestData['views'][view]["config"]["paddingTop"]) ? manifestData['views'][view]["config"]["paddingTop"] : 0, paddingBottom: (manifestData['views'][view]["config"]["paddingBottom"]) ? manifestData['views'][view]["config"]["paddingBottom"] : 0,
                    paddingLeft: (manifestData['views'][view]["config"]["paddingLeft"]) ? manifestData['views'][view]["config"]["paddingLeft"] : 0, paddingRight: (manifestData['views'][view]["config"]["paddingRight"]) ? manifestData['views'][view]["config"]["paddingRight"] : 0,}}>
                    {(manifestData['features'][manifestData['views'][view]['config']['feature']]['type'] === "modal") ? <button style={{
                            background: (manifestData['views'][view]['config']['backgroundColor']) ? manifestData['views'][view]['config']['backgroundColor'] : manifestData["stylesheet"]["colors"]["primary"],
                        }} type={"button"} onClick={() => handleOpenModal(manifestData['features'][manifestData['views'][view]['config']['feature']]['view'],manifestData['features'][manifestData['views'][view]['config']['feature']]['title'])} className={classes.homepageButton}>{manifestData['views'][view]['config']['title']}</button>
                        : (manifestData['features'][manifestData['views'][view]['config']['feature']]['type'] !== "webview" || !manifestData['features'][manifestData['views'][view]['config']['feature']]['subtype'].includes('external')) ? <Link to={manifestData['views'][view]['config']['feature']} >
                            <button style={{
                                background: (manifestData['views'][view]['config']['backgroundColor']) ? manifestData['views'][view]['config']['backgroundColor'] : manifestData["stylesheet"]["colors"]["primary"],
                            }} type={"button"} className={classes.homepageButton}>{manifestData['views'][view]['config']['title']}</button>
                        </Link> : <a target={"_blank"} rel="noopener noreferrer" href={manifestData['features'][manifestData['views'][view]['config']['feature']]['url']} >
                            <button style={{
                                background: (manifestData['views'][view]['config']['backgroundColor']) ? manifestData['views'][view]['config']['backgroundColor'] : manifestData["stylesheet"]["colors"]["primary"],
                            }} type={"button"} className={classes.homepageButton}>{manifestData['views'][view]['config']['title']}</button>
                        </a>
                    }
                </div>;
            case 'label':
                return <p dangerouslySetInnerHTML={{__html: manifestData['views'][view]["config"]['text']}} />;
            case "horizontalLayout":
                return <HorizontalSublayout classes={classes} manifestData={manifestData} config={manifestData['views'][view]['config']} />;
            case "video":
                return <OCVVideoPlayer config={manifestData['views'][view]['config']} />;
            case "facebookWidget":
                return <FacebookWidget config={manifestData['views'][view]['config']} />;
            case "twitterWidget":
                return <TwitterWidget config={manifestData['views'][view]['config']} />;
            case "blogSlider":
                return <OCVBlogSlider link={manifestData['features'][manifestData['views'][view]['config']['feature']]['url']} />;
        }
    }

    const {title, classes, items, manifestData, thirdLevel} = props;
    let navItems = (items !== null && manifestData !== null) ? items.map((item: string, index: any) => (
        (manifestData['features'][item]['type'] !== null && manifestData['features'][item]['type'] !== "submenu")
            ? (manifestData['features'][item]['type'] !== null && manifestData['features'][item]['type'] === "webview" && !manifestData['features'][item]['subtype'].includes("internal"))
                ? <a key={index+ item} rel="noopener noreferrer" target={"_blank"} className={classes.navSubmenuLink} href={manifestData['features'][item]['url']}><ListItem className={classes.navSubmenuItem}>{manifestData['features'][item]['title']}</ListItem></a>
                : (manifestData['features'][item]['type'] === 'modal') ? <span key={index+ item}  className={classes.navSubmenuLink} onClick={() => handleOpenModal(manifestData['features'][item]['view'], manifestData['features'][item]['title'])}><ListItem className={classes.navSubmenuItem}>{manifestData['features'][item].title}</ListItem></span> : <NavLink key={index + item}  to={'/' + item} className={classes.navSubmenuLink}><ListItem className={classes.navSubmenuItem}>{manifestData['features'][item].title}</ListItem></NavLink> 
                //<NavLink key={index+ item} className={classes.navSubmenuLink} to={'/'+item}><ListItem  className={classes.navSubmenuItem}>{manifestData['features'][item]['title']}</ListItem></NavLink>
            : (<ListItem key={index + item} className={classes.navThirdLevelListItem}><NavSubmenu thirdLevel={true} title={manifestData['features'][item]['title']} classes={classes} items={manifestData['features'][item].items} manifestData={manifestData}/></ListItem>)
    )) : '';
    return (
        <>
            <Dropdown className={classes.navSubmenuTitle} isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                    tag="a"
                    onClick={toggle}
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                >{title}</DropdownToggle>
                <DropdownMenu className={(!thirdLevel) ? classes.navSubmenu : classes.navThirdLevelSubmenu}>
                    <List style={{padding: "0"}}>{navItems}</List>
                </DropdownMenu>
            </Dropdown>
            <ReactModal className='NavSubmenuModalContent' overlayClassName='NavSubmenuModal' contentLabel="Minimal Modal Example" onRequestClose={() => handleCloseModal()} isOpen={showModal}>
                <div className='NavSubmenuDiv'>
                    <h4 className="NavSubmenuModalTitle">{modalTitle}</h4>
                    <IconButton aria-label="Close" className='NavSubmenuCloseButton' onClick={() => handleCloseModal()}>
                        <FontAwesomeIcon className="NavSubmenuIcon" icon={["fas", "times"]}/>
                    </IconButton>
                </div>
                {getView(modalView, manifestData, classes)}
            </ReactModal>
        </>
    );
};
