import React from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import './TwoColumnView.css';

export default function TwoColumnView(props: any) {
    const {manifestData, layoutConfig, classes} = props;
    return (
        <Row>
            <Col className='TwoColumnViewColOne' style={{paddingLeft: 0, paddingRight: 0}} xs={10} sm={4}>
                <img className="img-fluid" src={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[layoutConfig.img]['url'] : ''} alt={manifestData['stylesheet'].images ? manifestData['stylesheet'].images[layoutConfig.img]['altText'] : ''} />
            </Col>
            <Col className='TwoColumnViewColTwo' xs={10} sm={6}>
                <h2 className='TwoColumnViewHeader' dangerouslySetInnerHTML={{__html: layoutConfig.header}} />
                <p className='TwoColumnViewParagraph' dangerouslySetInnerHTML={{__html: layoutConfig.text}} />
                <Link to={'/' + layoutConfig.feature}><button type={"button"} className={classes.buttonLargeOutline}>{layoutConfig.buttonText}</button></Link>
            </Col>
        </Row>
    );
};
