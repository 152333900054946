import React from 'react';
import {Row, Col} from 'reactstrap';
import FacebookWidget from '../../MainComponents/FacebookWidget'
import TwitterWidget from '../../MainComponents/TwitterWidget';

export default function OCVSocialMedia(props: any) {
    return (
        <Row style={{padding: '32px 16px'}}>
            <Col xs={10} sm={10} md={7}>
                <TwitterWidget config={props['config']['twitterConfig']}/>
            </Col>
            <Col xs={10} sm={10} md={3}>
                <FacebookWidget config={props['config']['facebookConfig']}/>
            </Col>
        </Row>
    )
}