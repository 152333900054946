import React from "react";
import Iframe from "react-iframe";

declare global {
    interface Window {
        fbAsyncInit: any;
        FB: any
    }
    interface Element {
        src: string;
        async: boolean;
        defer: boolean;
    }
}

export default function FacebookWidget(props: any) {
    const {config} = props;
    return <Iframe title={'Facebook'} url={config["link"]} position="relative" width={"100%"} height={"700px"} />;
};
