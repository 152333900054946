import { createStyles } from "@material-ui/core";

const sliderStyle = createStyles({
  slider: {
    position: "relative",
    height: "100%",
    width: "auto",
  },
  innerContainer: {
    width: "100%",
    height: "100%",
    margin: "0",
    position: "relative",
  },
  slide: {
    textAlign: "center",
    width: "100%",
    background: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  images: {
    objectFit: "fill",
    width: "100%",
    height: "550px",
    "@media (max-width: 960px)": {
      height: "350px",
    },
    "@media (max-width: 600px)": {
      height: "150px",
    },
  },
  sliderOverlayText: {
    fontFamily: "Montserrat, sans-serif",
    letterSpacing: "2.5px",
    color: "#FFF",
    textTransform: "uppercase",
    fontSize: "32px",
    zIndex: 11,
    position: "absolute",
    bottom: 32,
    left: 64,
    "@media (max-width: 600px)": {
      fontSize: "20px",
      left: 16,
    },
  },
});

export default sliderStyle;
