import React from "react";

export default function Label(props: any) {
  const {
    fontSize,
    paddingTop,
    paddingBottom,
    paddingHorizontal,
    alignment,
    backgroundColor,
    labelText,
    color,
  } = props.labelConfig;
  return (
    <div
      style={{
        fontSize: fontSize + "px",
        paddingTop: paddingTop + "px",
        paddingBottom: paddingBottom + "px",
        paddingLeft: paddingHorizontal + "px",
        paddingRight: paddingHorizontal + "px",
        textAlign: alignment,
        backgroundColor: backgroundColor
          ? backgroundColor
          : "linear-gradient(to right, #0063a8, #0081bc, #249ecc, #4ebbda, #78d8e7)",
        color: color ? color : "white",
      }}
      dangerouslySetInnerHTML={{ __html: labelText }}
    />
  );
}
