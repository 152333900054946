import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import * as moment from 'moment';
import LazyLoad from 'react-lazyload';
import Pagination from "../../../MainComponents/Pagination";
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import './OCVBlogList.css';

export default function OCVBlogList(props: any) {
    const [currentEntries, setCurrentEntries] = useState(props.entries.slice(0, props.perPage));
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState("");

    const onPageChanged = (data: {currentPage: number, pageLimit: number}) => {
        const {currentPage, pageLimit} = data;
        const {entries} = props;
        const offset = (currentPage - 1) * pageLimit;
        const currentEntries = entries.slice(offset, offset + pageLimit);
        setCurrentEntries(currentEntries);
    };
    const openLightbox = (event: {preventDefault: () => void}, image: string) => {
        event.preventDefault();
        setLightboxIsOpen(true);
        setCurrentImage(image);
    };
    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const MyLoader = () => (
        <ContentLoader>
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
    );

    const {classes, route, entries, submitATipLink, perPage, subtypes} = props;
    return (
        <>
            <Row>
                {currentEntries && (currentEntries.length !== 0) ? currentEntries
                    .map((blogEntry: any, index: number) => (
                        <Col key={blogEntry.title+index} /*style={{ padding: '20px', margin: '20px 0px'}}*/ xs={10} sm={10} md={10}>
                            <LazyLoad once>
                                <Paper id={blogEntry['_id']['$id']} className='OCVBlogListPaperOne'>
                                    <div className='OCVBlogListExternalDiv'>
                                        {(blogEntry.images.length === 0) ? null :
                                            <div className='OCVBlogListInternalDivOne'>
                                                <div onClick={(e) => openLightbox(e, blogEntry.images[0].large)} className='OCVBlogListNestedDivOne'>
                                                    <img alt={blogEntry.title} className='img-fluid OCVBlogListNestedImg' src={blogEntry['images'].length !== 0 ?  blogEntry.images[0].large : null} />
                                                </div>
                                            </div>}
                                        <div className='OCVBlogListInternalDivTwo'>
                                            <h4>{blogEntry.title}</h4>
                                            {(!("date" in blogEntry) || subtypes.includes('noDates')) ? '' : <span className='OCVBlogListSpan'><strong>Posted on {moment.unix((blogEntry.date !== null) ? blogEntry['date']['sec'] : ' ').format('LL')}</strong></span>}
                                            <div className={classes.blogEllipsis + ' OCVBlogListNestedDivTwo'} dangerouslySetInnerHTML={{__html: blogEntry.content}} />
                                            <Link className='OCVBlogListLinkOne' to={{
                                                pathname: `/${route}/${blogEntry['_id']['$id']}`,
                                            }}>Read More</Link>
                                            {(submitATipLink === true)
                                                ? <Link className='OCVBlogListLinkTwo' to={"/submitATip"}>
                                                        <button type={"button"} className={"btn btn-danger"}>Submit a Tip</button>
                                                </Link> : ""}
                                        </div>
                                    </div>
                                </Paper>
                            </LazyLoad>
                        </Col>
                    ))
                    : <Col className='OCVBlogListCol' xs={10} sm={10} md={10}>
                        <Paper className='OCVBlogListPaperTwo'>
                            <MyLoader />
                        </Paper>
                    </Col>}
            </Row>
            <Row>
                <Col xs={10} sm={10} md={8}>
                    <Pagination
                        totalRecords={entries.length}
                        pageLimit={perPage}
                        pageNeighbours={0}
                        onPageChanged={onPageChanged}
                    />
                </Col>
            </Row>
            <Lightbox backdropClosesModal onClose={closeLightbox} isOpen={lightboxIsOpen} images={[{src: currentImage}]} />
        </>
    );
};